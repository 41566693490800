import { filterEmpty } from './lists/filterEmpty';
import { safePushAll } from './lists/safePushAll';
import { emptyStringOrDefault } from './strings/emptyStringOrDefault';

if (process.env.NEXT_PUBLIC_ENV !== 'prod') {
    console.log('environment: ', process.env.NEXT_PUBLIC_ENV);
}

enum Environments {
    PROD,
    UAT,
    N1QA,
    N1DEV,
    DEV,
}

const apiBase = {};
apiBase[Environments.PROD] = 'https://api.customer.sites.dteenergy.com/public/outageadmin/v1/admin';
apiBase[Environments.UAT] = 'https://test.api.customer.sites.dteenergy.com/public/uat/outageadmin/v1/admin';
apiBase[Environments.N1QA] = 'https://test.api.customer.sites.dteenergy.com/public/n1qa/outageadmin/v1/admin';
apiBase[Environments.N1DEV] = 'https://test.api.customer.sites.dteenergy.com/public/n1dev/outageadmin/v1/admin';
apiBase[Environments.DEV] = 'https://test.api.customer.sites.dteenergy.com/public/n1dev/outageadmin/v1/admin';

const webHost = {};
webHost['dev.outageadmin.sites.dteenergy.com'] = Environments.DEV;
webHost['n1dev.outageadmin.sites.dteenergy.com'] = Environments.N1DEV;
webHost['n1qa.outageadmin.sites.dteenergy.com'] = Environments.N1QA;
webHost['uat.outageadmin.sites.dteenergy.com'] = Environments.UAT;
webHost['outageadmin.sites.dteenergy.com'] = Environments.PROD;

function getHostname() {
    if (typeof window === 'undefined') {
        return '';
    }
    return window?.location?.hostname;
}

class Config {
    // Default to prod values
    private apiHost: string = apiBase[Environments.PROD];
    private webEnv = undefined;

    constructor() {
        const webEnv = webHost[getHostname()];
        this.webEnv = webEnv;

        if (webEnv !== Environments.PROD) {
            // See if we have a local override
            this.apiHost = process.env.NEXT_PUBLIC_API_BASE || apiBase[webEnv];
        }
    }

    public pageTitle(parts?: string[]): string {
        let titleParts = ['DTE Outage'];

        titleParts = safePushAll(titleParts, parts);
        titleParts = filterEmpty(titleParts);

        return titleParts.join(' - ');
    }

    get isProd(): boolean {
        return [Environments.PROD].includes(this.webEnv);
    }

    get isUAT(): boolean {
        return [Environments.UAT].includes(this.webEnv);
    }

    get isQA(): boolean {
        return [Environments.N1QA].includes(this.webEnv);
    }

    get isDev(): boolean {
        return [Environments.N1DEV].includes(this.webEnv);
    }

    get isLocal(): boolean {
        return [Environments.DEV].includes(this.webEnv);
    }

    get apiSubscriptionKey(): string {
        if (this.isProd) {
            return '3e01be5f163b4919ac1a1d5c78524e48';
        }

        let value = '';

        if (typeof window !== 'undefined') {
            value = emptyStringOrDefault(window.localStorage.getItem('subscriptionKey'));
        } else {
            console.warn('Unable to get subscription key, window is undefined');
        }

        return value;
    }

    get apiBase(): string {
        return this.apiHost;
    }
}

export const config = new Config();
